@font-face {
	font-family: "Rajdhani-Regular";
	src: local("Rajdhani-Regular"),
		url("./fonts/Rajdhani-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Rajdhani-Medium";
	src: local("Rajdhani-Medium"),
		url("./fonts/Rajdhani-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Rajdhani-Bold";
	src: local("Rajdhani-Bold"),
		url("./fonts/Rajdhani-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "ChangaOne-Regular";
	src: local("ChangaOne-Regular"),
		url("./fonts/ChangaOne-Regular.ttf") format("truetype");
}

* {
	position: relative;
}

body {
	margin: 0;
	padding-top: 80px;
	font-family: "Rajdhani-Regular";
	color: #6e6e6e;
	overflow-y: scroll;
	background: url(assets/bg.png);
	background-size: 100%;
}

textarea:not(.resizable) {
	resize: none;
}

label {
	margin-bottom: 10px !important;
}

.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-center {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: left;
	justify-content: left;
}

.flex-row-right {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

a:not(.decorated) {
	text-decoration: none;
}

.background-default {
	background-color: #3a2156 !important;
}

.background-white {
	background-color: #ffffff !important;
}

.background-light {
	background-color: #f4f2f7 !important;
}

.background-gray {
	background-color: #e6e3e9 !important;
}

.navbar-toggler,
.navbar-toggler:focus {
	border: none;
	outline: none;
	box-shadow: none;
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' strokeWidth='2' strokeLinecap='round' strokeMiterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/* .container {
	padding: 0 5vw;
} */

.p-10 {
	padding: 10px;
}

.p-20 {
	padding: 20px;
}

.p-30 {
	padding: 30px;
}

.p-40 {
	padding: 40px;
}

.p-50 {
	padding: 50px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-50 {
	margin-bottom: 50px;
}

.upper {
	text-transform: uppercase;
}

.opacity-5 {
	opacity: 0.5;
}

.opacity-7 {
	opacity: 0.7;
}

.white-text,
.white-text * {
	color: #ffffff !important;
}

.light-text,
.light-text * {
	color: #9b8da8 !important;
}

.dark-text,
.dark-text * {
	color: #000000 !important;
}

.default-text,
.default-text * {
	color: #3a2156 !important;
}

.link-text {
	color: #61289d !important;
}

.link-text:hover {
	color: #916cb8 !important;
}

.rounded {
	border-radius: 6px !important;
}

.warning-text,
.warning-text * {
	color: #ff6261 !important;
}

.yellow-text,
.yellow-text * {
	color: #ffcb2a !important;
}

.regular-text {
	font-family: "Rajdhani-Regular" !important;
}

.bold-text {
	font-family: "Rajdhani-Bold" !important;
}

.medium-text {
	font-family: "Rajdhani-Medium" !important;
}

.changa-regular {
	font-family: "ChangaOne-Regular" !important;
}

.title-lg {
	font-size: 2rem;
}

.title-sm {
	font-size: 1.3rem;
}

.text-xl {
	font-size: 1.5rem;
}

.text-lg {
	font-size: 1.125rem;
}

.text-md {
	font-size: 1rem;
}

.text-sm {
	font-size: 0.875rem;
}

.label-md {
	font-size: 0.75rem !important;
}

.label-sm {
	font-size: 0.563rem;
}

strong {
	font-family: "Rajdhani-Bold";
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 8;
	height: 80px;
}

.nav-brand img {
	height: 50px;
	margin-right: 30px;
}

.main-nav .nav-link {
	font-size: 1rem;
	margin-right: 25px;
	padding: 0;
}

.main-nav .nav-link:hover {
	opacity: 0.8;
}

.page-section {
	background-size: cover !important;
	background-position: center !important;
}

.home-banner {
	height: 40vh;
}

.banner-content {
	height: 100%;
	text-align: center;
}

.banner-text-container {
	position: absolute;
	z-index: 3;
	height: 100%;
	width: 100%;
	padding: 0 23vw;
	text-align: center;
}

.banner-text {
	color: #fdcf09;
	font-size: 3rem;
	line-height: 3.5rem;
}

.banner-btn-container {
	margin-top: 2rem;
}

.btn {
	border: none !important;
}

.btn-primary {
	background-color: #3a2156 !important;
	color: #fff !important;
}

.btn-primary:hover {
	background-color: #4e3569 !important;
}

.btn-primary:focus {
	background-color: #3a2156 !important;
	box-shadow: 0 0 0 3px rgba(58, 33, 86, 0.5) !important;
}

.btn-danger {
	background-color: #e32c26;
}

.btn-purple {
	background-color: #46296c !important;
	color: #fff !important;
}

.btn-purple:hover {
	background-color: #503a67 !important;
}

.btn-purple:focus {
	background-color: #46296c !important;
	box-shadow: 0 0 0 3px rgba(70, 41, 108, 0.5) !important;
}

.btn-purple:active {
	background-color: #382056 !important;
}

.btn-warning {
	background-color: #ff6261;
	color: #fff !important;
}

.btn-warning:hover {
	background-color: #fa8585;
}

.btn-warning:focus {
	background-color: #ff6261 !important;
	box-shadow: 0 0 0 3px rgba(255, 98, 97, 0.5) !important;
}

.btn-yellow {
	background-color: #ffcb2b !important;
	color: #3a2156 !important;
}

.btn-yellow:hover {
	background-color: #fcd45f !important;
}

.btn-yellow:focus {
	background-color: #ffcb2b !important;
	box-shadow: 0 0 0 3px rgba(255, 203, 43, 0.5) !important;
}

.btn-yellow:active {
	background-color: #ebba27 !important;
}

.btn-gray {
	background-color: #e5e3e8 !important;
	color: #9b8da8 !important;
}

.btn-gray:hover {
	background-color: #d8d6da !important;
}

.btn-gray:focus {
	background-color: #e5e3e8 !important;
	box-shadow: 0 0 0 3px rgba(229, 227, 232, 0.5) !important;
}

.banner-btn {
	font-size: 1rem;
	width: 150px;
	height: 50px;
	margin: 0 1vh;
}

.banner-pikachu {
	position: absolute;
	height: 45vh;
	right: 4vw;
	bottom: -5vh;
}

.home-nav-tabs .nav-link {
	background-color: #3a2156 !important;
	border: none !important;
	border-radius: 6px !important;
}

.home-nav-tabs .nav-link:not(.active) {
	opacity: 0.8;
}

.home-nav-tabs .nav-link.active {
	background-color: #46296c !important;
}

.nav-tabs .nav-link.active {
	font-family: "Rajdhani-Bold";
}

.home-nav-tabs {
	height: 60px;
	border: none !important;
	padding: 0 20px;
	margin-bottom: 30px;
}

.deck {
	margin-bottom: 30px;
	overflow: hidden;
	border: 1px solid #eee;
	background-color: #f4f2f7;
	transition: 0.3s;
}

.deck-content {
	padding: 20px 20px 0 20px;
}

.deck-sub-row {
	height: 50%;
}

.deck-sub-row img {
	height: 100%;
	width: 100%;
}

.deck-sub-row.top {
	padding-bottom: 4px;
}

.deck-sub-row.bottom {
	padding-top: 4px;
}

.deck:hover {
	transform: scale(1.02);
	box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
}

.deck-photo {
	width: 100%;
	height: 30vh;
	max-height: 200px;
	background-size: 110% !important;
	background-position: center 4% !important;
}

.deck-info {
	padding: 10px;
	width: 100%;
}

.deck-name {
	margin-top: 12px;
	width: 100%;
	padding-right: 10px;
	height: 3rem;
}

.deck-name > span {
	font-size: 1.2rem;
	line-height: 1.5rem;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.deck-tags {
	margin-top: 10px;
	margin-bottom: 15px;
}

.deck-tags > span {
	background-color: #e6e3e9;
	border-radius: 100px;
	padding: 2px 10px;
	margin-right: 5px;
	width: fit-content;
}

.deck-tags > span.invalid {
	background-color: #f9df9d;
}

.invalid-icon {
	margin-right: 5px;
	color: #f0bc37;
}

.deck-misc {
	background-color: #e6e3e9;
	border-radius: 0 0 6px 6px;
	padding: 8px 20px;
}

/* .deck-info .row {
  height: 100%;
  width: 100%;
  padding: 0 10px;
} */

.deck-format {
	font-size: 0.85rem;
}

.content {
	padding: 30px 0;
}

.full-page {
	min-height: calc(100vh - 80px);
	width: 100%;
}

.half-page {
	min-height: 70vh;
	width: 100%;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right !important;
}

.form-card {
	padding: 20px;
	max-width: 450px;
}

.login-btn {
	height: 48px;
	font-weight: 600;
}

.form-control:focus,
.form-control:active {
	outline: none !important;
	border-color: #4e3569 !important;
	box-shadow: none !important;
}

.form-control.search-text:focus,
.form-control.rbt-input:focus,
.form-control.search-text:active,
.form-control.rbt-input:active {
	border-color: #ced4da !important;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.deck-header {
	height: 45vh;
	background-size: cover !important;
	background-position: center 15% !important;
}

.deck-header-content {
	height: 100%;
	background-color: rgb(58, 33, 86, 0.7);
}

.deck-title {
	font-size: 2.5rem;
}

.deck-title-text {
	background-color: transparent !important;
}

.deck-header-info {
	margin-left: 2vw;
	margin-right: 2vw;
}

.deck-card-price {
	visibility: hidden;
}

.deck-card:hover .deck-card-price {
	visibility: visible;
}

.deck-card:active .deck-card-price {
	visibility: hidden;
}

.deck-card:active {
	padding: 0;
}

.deck-card {
	text-align: center;
	transition: 0.3s;
	opacity: 1 !important;
	height: fit-content;
	z-index: 2;
}

.deck-card > a {
	width: 100%;
}

.deck-card.parent,
.deck-card.stacked {
	margin-bottom: calc(100% - (200% + 50px)) !important;
}

.deck-card:not(.parent) {
	margin-bottom: 10px;
}

.deck-card.stacked:not(.parent):hover {
	margin-bottom: 10px !important;
}

.deck-card:last-of-type {
	margin-bottom: 10px !important;
}

/* .deck-card:not(:last-of-type) {
  margin-bottom: calc(100% - (200% + 50px));
} */

/* .deck-card.parent,
.deck-card.copy {
  margin-bottom: calc(100% - (200% + 80px));
} */

/* .deck-card:not(:last-of-type):hover {
  margin-bottom: 10px;
} */

/* .deck-card.parent:not(:last-of-type):hover {
  margin-bottom: 15%;
} */

.deck-card-img {
	border-radius: 13px;
	width: 100%;
	border: 1px solid #ffffff;
}

.deck-supertype {
	font-size: 1.2rem;
	line-height: 1.2rem;
}

.deck-copy-area {
	width: 100%;
	min-height: 55vh;
	border-radius: 13px;
}

.deck-copy-area.bordered {
	border: 1px solid #cecdcd;
}

.deck-copy-area.can-drop {
	border-color: #4e3569;
}

.copy-btn {
	width: 100%;
}

.qty-picker {
	position: absolute;
	height: 100% !important;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 13px;
	display: none;
}

.deck-card.active .qty-picker {
	display: flex !important;
}

.deck-card:not(.parent):hover .qty-picker {
	display: flex;
}

.qty-label {
	margin: 0;
}

.qty-btn {
	background-color: transparent !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	font-size: 1.5rem;
	margin: 0 20px;
}

.card-img-container {
	width: 50vh;
}

.card-name {
	font-size: 1.5rem;
	line-height: 1.5rem;
}

.card-body {
	background-color: #f4f1f8;
}

.card-type {
	font-size: 1.3rem;
}

.card-type-icon {
	height: 20px;
	margin-right: 10px;
	border: 1px solid #6e6e6e;
	border-radius: 50%;
}

.card-misc-label {
	font-size: 1rem;
}

.card-misc {
	min-height: 27px;
}

.card-set-name {
	font-size: 1rem;
}

.card-set-icon {
	height: 35px;
}

.card-add-to-deck {
	width: 100%;
}

.page-title {
	font-size: 2rem;
}

.card-filters,
.deck-filters {
	border-radius: 6px;
	padding: 7px;
	height: fit-content;
}

.card-filters.main,
.deck-filters.main {
	background-color: #392156;
	padding: 5px 20px;
	margin-bottom: 30px;
}

.clear-filter-btn {
	margin-left: 10px;
	width: 100px;
}

.format-dropdown-container {
	width: 125px;
	margin-left: 20px;
}

.sm-format-dropdown-container {
	width: 125px;
}

.sm-format-dropdown {
	display: none;
}

.md-format-dropdown {
	display: none;
}

.filter-action-btn {
	width: 130px !important;
	background-color: #4e3569 !important;
	border: none !important;
	box-shadow: none !important;
	margin: 0;
}

.format-dropdown {
	width: fit-content !important;
	padding-left: 0 !important;
	margin-left: 0;
	border: none !important;
	box-shadow: none !important;
	cursor: pointer;
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 strokeLinecap=%27round%27 strokeLinejoin=%27round%27 strokeWidth=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
}

/* .format-dropdown:hover {
	background-color: #f5bc6c;
}

.format-dropdown:focus {
	background-color: #ffb143 !important;
	box-shadow: 0 0 0 3px rgba(255, 177, 67, 0.5) !important;
} */

.btn-icon {
	margin-right: 5px;
}

.pagination-btn {
	border-radius: 50%;
	width: 33px !important;
	height: 33px !important;
	border: none !important;
	outline: none !important;
	padding: 0 !important;
	margin-left: 10px;
	background-color: transparent;
}

.pagination-btn:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.pagination-btn:focus {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5) !important;
}

.comments-section {
	border-radius: 10px;
	background-color: #ffffff;
	padding: 5vw;
}

.modal-body {
	max-height: 65vh;
	overflow-y: auto;
}

.modal-lg .modal-body {
	height: 65vh;
	overflow-y: scroll;
}

.add-cards-modal .modal-body {
	max-height: 80vh;
	height: 80vh !important;
}

#myDecksModal .modal-body {
	padding: 0 !important;
}

.user-deck {
	cursor: pointer;
	margin: 0;
	padding: 1rem 1.5rem;
	border-bottom: 1px solid #eee;
}

.user-deck:not(.no-hover):hover {
	background-color: rgb(78, 53, 105, 0.3);
}

.user-decks-btn {
	font-size: 0.8rem;
	width: 100px;
}

.comment-textarea {
	height: 100px;
	resize: none;
}

.tag-wrapper {
	background-color: #4e3569;
	color: #fff;
	padding: 2px 25px 2px 10px;
	border-radius: 10px;
	font-size: 0.9rem;
	margin-right: 10px !important;
	margin-bottom: 10px !important;
}

.tag-wrapper button {
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	color: #fff;
	background-color: transparent;
	position: absolute;
	height: 100%;
	bottom: 0;
	right: 0;
	padding-top: 2px;
	font-size: 1.5rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.tag-input {
	margin-top: 20px;
	width: 50%;
}

.tag-name {
	font-size: 0.9rem;
}

.dropdown-item {
	cursor: pointer;
}

.login-to-comment {
	background-color: #f1eff1;
	border-radius: 10px;
}

.loader {
	height: calc(100vh - 80px);
	width: 100%;
}

.search-loader {
	height: 50vh !important;
}

.deck-card-loader {
	height: 8vh !important;
}

.ball {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background: white;
	position: relative;
	box-shadow: -20px 0 rgba(0, 0, 0, 0.1) inset;
	animation: roll 1s ease-in-out infinite;
	background: linear-gradient(
		to bottom,
		#e83e35 0%,
		#e83e35 50.5%,
		#ffffff 50.51%,
		#ffffff 100%
	);
}

.ball:after {
	content: "";
	position: absolute;
	top: calc(35px - 3px);
	left: 0;
	width: 70px;
	height: 6px;
	background: #3f3f3f;
}

.ball:before {
	content: "";
	position: absolute;
	top: calc(50% - 9px);
	left: calc(50% - 9px);
	width: 18px;
	height: 18px;
	border: solid 6px #3f3f3f;
	border-radius: 50%;
	background: white;
	z-index: 1;
	box-shadow: 0 0 15px -2px #c62828 inset;
	animation: button 3s ease infinite;
}

@keyframes roll {
	from {
		transform: rotate(0);
	}
	90%,
	to {
		transform: rotate(720deg);
	}
}

@keyframes button {
	from,
	50%,
	to {
		box-shadow: 0 0 15px -2px #c62828 inset;
	}

	25%,
	75% {
		box-shadow: 0 0 10px -2px #1300ea inset;
	}
}

.test {
	border: 1px solid red !important;
}

.card-item {
	padding: 20px;
	transition: 0.3s;
	overflow: hidden;
}

.card-item:hover {
	background-color: #d7d3dd !important;
}

.card-item:hover .card-btn-container {
	display: block;
}

.card-item-img {
	width: 100%;
	border-radius: 10px;
	transition: 0.3s;
}

/* .card-item-img:hover {
	transform: scale(1.03);
	box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 15%);
} */

.clipboard {
	position: fixed;
	bottom: 0;
	height: 55px;
	left: 0;
	width: 100%;
	overflow: hidden;
	transition: 0.3s;
	z-index: 6;
}

.clipboard-header {
	height: 55px;
	width: 100%;
}

.clipboard.active {
	background-color: #ffcb2b;
	z-index: 9;
}

.clipboard.active .clipboard-header {
	background-color: #ffcb2b !important;
	padding: 0 !important;
}

.clipboard.active .clipboard-footer {
	display: flex;
}

.clipboard-toggle {
	height: 100%;
	padding: 0 3rem;
	border-radius: 5px 5px 0 0;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background-color: #ffcb2b !important;
	border-bottom: none;
	cursor: pointer;
	user-select: none;
}

.clipboard .clipboard-toggle:hover {
	background-color: #f7c633 !important;
}

.clipboard.active {
	height: calc(65vh + 70px);
}

.clipboard.active .clipboard-toggle {
	width: 100% !important;
}

.clipboard.active .clipboard-toggle-icon {
	transform: rotate(180deg);
	margin-bottom: 3px;
}

.clipboard-body {
	height: calc(100% - 125px);
	width: 100%;
	background-color: #ffffff;
	padding: 30px 0;
	overflow-y: auto;
}

.clipboard.active .clipboard-body {
	border-top: 1px solid #ffcb2b !important;
}

.clipboard-cards {
	height: 100%;
}

.clipboard-cards-body {
	overflow-y: auto;
}

.clipboard-cards-options {
	width: 100%;
	height: 15%;
}

.clipboard-btn {
	width: 150px;
	height: 48px;
	margin-left: 10px;
	font-weight: 600;
}

.clipboard-btn.clear-btn {
	border: 1px solid #3a2156 !important;
}

.copy-card-btn,
.copy-card-btn:focus {
	position: absolute;
	top: 13%;
	right: 10%;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.6);
	box-shadow: none;
}

.copy-card-btn.grid {
	top: 10px;
	right: 10px;
	/* font-size: 0.8rem; */
}

.copy-card-info-btn,
.copy-card-info-btn:focus {
	position: absolute;
	top: 10%;
	right: 10%;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.6);
	box-shadow: none;
	padding: 0.8rem 1rem;
}

.copy-card-btn:hover,
.copy-card-info-btn:hover {
	background-color: #4e3569;
}

.copy-card-btn:active,
.copy-card-info-btn:active {
	background-color: #3a2156 !important;
	box-shadow: 0 0 0 3px rgba(58, 33, 86, 0.5) !important;
}

.card-qty-btn {
	padding: 0;
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
}

.card-qty-btn:focus {
	box-shadow: none !important;
}

.card-qty-icon {
	font-size: 0.6rem;
}

.clipboard-qty,
.deck-card-qty {
	width: 2rem;
	text-align: center;
	font-weight: 700;
}

.clipboard-preview {
	padding: 15px !important;
	min-height: 420px;
}

.clipboard-img {
	height: 100%;
}

.Snackbar_snackbar-wrapper-bottom-center__21ghq {
	z-index: 10 !important;
}

.section-options {
	width: 100%;
}

.section-btn,
.section-btn:active,
.section-btn:focus {
	font-size: 0.9rem;
	background-color: transparent !important;
	box-shadow: none;
}

.section-btn:hover {
	opacity: 0.7;
}

.deck-action-icon {
	font-size: 1rem;
}

.deck-action-btn {
	width: 60px;
	height: 40px;
}

.deck-action-btn:first-of-type {
	margin-right: 5px;
}

.engage-count {
	font-size: 1rem;
	margin-left: 5px !important;
	font-family: "Rajdhani-Medium" !important;
}

.deck-card-tags {
	padding: 5px 0;
}

.deck-tags-label {
	font-size: 0.9rem;
}

.deck-card-tag {
	padding: 0 8px;
	border-radius: 10px;
	margin-right: 7px;
}

.tag-name {
	font-size: 0.8rem;
}

.tag-name-container {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.section-title {
	width: 100%;
}

.deck-like-icon {
	cursor: pointer;
}

.deck-like-icon:hover {
	opacity: 0.7;
}

.footer {
	width: 100%;
	padding-top: 20px;
	padding-bottom: 45px;
}

.footer-nav {
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.footer-nav-link {
	font-size: 0.9rem;
}

.footer-social {
	font-size: 0.8rem;
}

.footer-text {
	opacity: 0.7;
	padding: 30px 12vw;
	font-size: 0.9rem;
}

.deck-btn {
	font-size: 0.85rem;
}

.deck-btn.active {
	background-color: #4e3569 !important;
}

.deck-list-item {
	display: block;
	width: 100%;
	border: 1px solid #ddd;
	cursor: pointer;
}

.deck-list-item:hover {
	background-color: #f5f5f5;
}

.deck-list-item.parent {
	display: none;
}

.deck-list-btn-container {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
}

.deck-grid-item {
	margin-bottom: 20px;
}

.deck-grid-item.active .qty-picker {
	display: flex !important;
}

.deck-grid-item:hover .qty-picker {
	display: flex;
}

.card-grid-qty {
	position: absolute;
	height: 50px;
	width: 50px;
	border-radius: 13px;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 3;
}

.card-grid-qty:not(.centered) {
	top: 0;
	right: 0;
}

.copy-list-card-btn,
.copy-list-card-btn:focus {
	background-color: #3a2156;
	font-size: 0.7rem;
	box-shadow: none;
}

.copy-list-card-btn:hover {
	background-color: #4e3569;
}

.copy-list-card-btn:active {
	background-color: #3a2156 !important;
	box-shadow: 0 0 0 3px rgba(58, 33, 86, 0.5) !important;
}

.filter-check {
	margin-bottom: 0.5rem;
	user-select: none;
}

.filter-check:not(.no-hover):hover {
	background-color: #eee;
}

.filter-check,
.filter-check *:not(input[type="text"]):not(textarea) {
	cursor: pointer;
}

.filter-check > label {
	margin-left: 10px;
	margin-bottom: 0 !important;
	width: 100%;
}

.filter-check > img {
	margin-left: 10px;
	height: 1.3rem;
}

.filter-section {
	overflow: hidden;
	border-bottom: 1px solid #eee;
	padding-bottom: 0;
	height: 0;
	transition: 0.3s;
}

.filter-section.active {
	height: fit-content;
	padding-bottom: 1rem;
}

.filter-toggle {
	padding: 1rem 0;
	cursor: pointer;
	user-select: none;
}

.filter-toggle-icon.active {
	transform: rotate(180deg);
}

.search-container {
	position: relative;
	z-index: 3;
	width: 350px;
}

.search-container:not(.card-search) {
	width: 350px;
}

.search-container input {
	padding-right: 10px !important;
	background-color: #46296c !important;
	color: #fff !important;
	border: none !important;
	height: 38px;
}

.search-input-icon {
	position: absolute;
	top: 4px;
	right: 4px;
	height: calc(100% - 8px);
	font-size: 0.875rem;
	color: #fff;
	opacity: 0.5;
	background-color: transparent !important;
}

.search-input-icon.cards {
	right: 105px;
}

.search-container input::placeholder {
	color: #fff !important;
	opacity: 0.5;
}

.search-container.card-search {
	width: 380px;
}

.search-container.card-search input {
	padding-right: 150px !important;
}

.search-container .wrapper {
	padding-right: 90px !important;
}

.full {
	width: 100% !important;
}

.full-y {
	height: 100% !important;
}

.hidden {
	display: none;
}

.search-submit {
	position: absolute;
	visibility: hidden;
}

.card-placeholder {
	width: 100%;
	border-radius: 10px;
}

.deck-card-placeholder,
.featured-card-placeholder {
	margin: 0 !important;
}

.Snackbar_snackbar-wrapper__ocbPJ {
	z-index: 1500 !important;
}

.deck-builder-row {
	padding: 10px;
	background-color: #ffffff;
	border: 1px solid #eee;
}

.deck-builder-row:hover {
	background-color: rgba(78, 53, 105, 0.07);
}

.deck-builder-img {
	height: 70px;
}

.deck-builder-info > span {
	display: block;
}

.deck-name-text {
	background-color: transparent;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	width: 100% !important;
}

.add-column-area {
	height: 60vh;
	background-color: transparent;
	border-radius: 10px;
}

.add-column-area * {
	visibility: hidden;
}

.add-column-area.can-drop {
	background-color: rgba(78, 53, 105, 0.2);
}

.add-column-area.can-drop * {
	visibility: visible;
}

.custom-column.can-drop {
	background-color: rgba(78, 53, 105, 0.2);
}

.custom-column {
	border-radius: 10px;
	height: fit-content;
	min-height: 50vh;
}

.card-backlog {
	min-height: 50vh;
}

.column-header {
	height: 1.2rem;
	padding: 0 5px;
}

.rename-column {
	height: 30px;
}

.dropdown {
	cursor: pointer;
	user-select: none;
}

.dropdown-item:active {
	background-color: #4e3569 !important;
}

.column-options {
	height: 1rem;
	width: 100%;
}

.comment-thumb {
	cursor: pointer;
}

.comment-thumb:hover {
	opacity: 0.7;
}

.set {
	padding: 20px;
	transition: 0.3s;
	height: 290px;
	overflow: hidden;
}

.set:hover {
	transform: scale(1.05);
}

.set-logo {
	max-width: 100%;
}

.set-logo-container {
	margin-bottom: 40px;
	height: 100px;
}

.set-name {
	font-size: 1rem;
}

.set-date {
	font-size: 0.8rem;
}

.set-header {
	height: 50vh;
	width: 100%;
	background-color: #4e3569;
}

.set-header > img {
	height: 25vh;
}

.set-section-header {
	font-size: 2.5rem;
}

.collection-img {
	height: 25vh;
}

.set-collection {
	transition: 0.3s;
}

.set-collection:hover {
	transform: scale(1.05);
}

.clipboard-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 4;
	display: none;
}

.clipboard-backdrop.active {
	display: block !important;
}

.subtype-header {
	font-size: 1.2rem;
}

.card-tooltip {
	width: fit-content !important;
	border: 2px solid #ddd !important;
	border-radius: 15px !important;
	padding: 10px !important;
}

.card-tooltip > img {
	height: 60vh;
}

.card-tooltip > span > img {
	height: 1.5rem;
}

.card-tooltip span {
	color: #3a2156 !important;
	font-size: 1rem;
}

.search-toggle {
	position: absolute;
	top: 0;
	right: 0;
	background-color: transparent;
	outline: none !important;
	box-shadow: none !important;
	height: 100%;
	padding: 0 10px;
	width: 100px;
	appearance: none;
	border-radius: 0 0.25rem 0.25rem 0;
	border: none;
	border-left: 1px solid #ddd;
	color: #fff;
}

.search-toggle option {
	color: #382056 !important;
}

.format-dropdown {
	color: #fff;
}

.dropdown-item.active {
	background-color: #4e3569;
}

.deck-card-symbol {
	height: 1.5rem;
}

.about-body {
	font-size: 1.2rem;
}

.contact-social {
	font-size: 1.2rem;
}

.nopage-img {
	height: 150px;
}

.edit-title-btn {
	position: absolute;
	right: -2.5rem;
	top: 0.5rem;
}

.text-view-header {
	border-radius: 7px 7px 0 0;
}

.preview-img {
	width: 100%;
}

.preview-price .col {
	border: 1px solid #eee;
}

.suggestions-container {
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	min-height: 80px;
	height: fit-content;
	border: 1px solid #eee;
	border-radius: 0 0 5px 5px;
	padding: 8px 0;
	overflow-y: auto;
}

.search-suggestion {
	width: 100%;
	padding: 7px 13px;
	display: block;
}

.deck-suggestion {
	padding: 10px 13px !important;
}

.suggestion-img {
	height: 50px;
	width: 50px;
	/* margin-right: 10px; */
}

.suggestion-img img {
	height: 100%;
}

.set-suggestion-img {
	height: 50px;
	width: 50px;
	/* margin-right: 10px; */
}

.suggestion-img > img {
	height: 100%;
	border-radius: 5px;
}

.suggestion-info > span {
	display: block;
}

.search-suggestion.selected,
.search-suggestion:hover {
	background-color: rgba(58, 33, 86, 0.1);
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.suggestions-loader {
	height: 80px;
	width: 100%;
}

.empty-tab {
	min-height: 35vh;
}

.invalid-cards {
	font-size: 0.85rem;
}

.deck-list-price {
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
}

.suggestions-format-container {
	padding: 5px 0;
	margin-bottom: 10px;
}

.suggestions-format {
	border: 1px solid #3a2156;
	border-radius: 13px;
	width: 100px;
	margin: 0 10px;
	cursor: pointer;
}

.suggestions-format.active {
	background-color: #3a2156;
	color: #fff !important;
}

.suggestions-format:not(.active):hover {
	background-color: #e6dcf2;
}

.deck-stats-container {
	position: absolute;
	bottom: 20px;
	left: 0;
	width: 100%;
}

.deck-control {
	margin-bottom: 50px;
	border-bottom: 2px solid #eee;
	padding-bottom: 10px;
}

.deck-warnings-container {
	margin-bottom: 20px;
	margin-top: -10px;
}

.deck-warning {
	margin-bottom: 10px;
	height: 48px;
	padding: 0 20px;
	background-color: #fef7d1;
	width: fit-content;
	border-radius: 11px;
}

.warning-icon {
	color: #f7c752;
	font-size: 1.5rem;
	margin-right: 10px;
}

.deck-warning span {
	color: #755118;
}

.filter-item {
	width: fit-content;
	padding: 3px 30px 3px 10px;
	border-radius: 7px;
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}

.filter-delete {
	position: absolute;
	top: 0;
	right: 10px;
	height: 100%;
}

.filters-container {
	margin-bottom: 20px;
}

.filters-label {
	margin-right: 10px;
}

.suggestion-sub-info {
	margin-right: 17px;
}

.suggestion-icon {
	margin-right: 5px;
	font-size: 0.65rem;
}

.suggestion-icon.calendar {
	margin-top: -1px;
}

.empty-page {
	height: calc(100vh - 300px);
}

.empty-page img {
	height: 150px;
}

.soon-img {
	height: 200px;
}

.about-img {
	height: 180px;
}

.about-section {
	padding: 80px 0;
}

.deck-text {
	padding-right: 130px;
}

.deck-action {
	position: absolute;
	top: 0;
	right: 12px;
	width: 125px;
}

.nav-sm {
	display: none;
}

.nav-toggle {
	position: absolute;
	right: 0;
	height: 60px;
	width: 30px;
	cursor: pointer;
}

.fries {
	width: 100%;
}

.fries > div {
	transition: 0.3s;
}

.mid-fry {
	width: 28px;
	height: 2px;
	float: right;
	background-color: #fff;
}

.top-fry {
	position: absolute;
	width: 28px;
	height: 2px;
	top: -9px;
	right: 0;
	opacity: 1 !important;
	background-color: #fff;
}

.bottom-fry {
	position: absolute;
	width: 28px;
	height: 2px;
	bottom: -9px;
	right: 0;
	background-color: #fff;
}

.fries.active .mid-fry {
	opacity: 0;
}

.fries.active .top-fry {
	transform: rotate(40deg);
	top: 0;
}

.fries.active .bottom-fry {
	transform: rotate(-40deg);
	bottom: 0;
}

.nav-panel {
	position: fixed;
	bottom: 100vh !important;
	right: 0;
	height: calc(100% - 80px);
	width: 100%;
	z-index: 7;
	transition: 0.3s ease-in;
	padding: 10px;
}

.nav-panel .nav-link {
	padding-left: 0;
	padding-right: 0;
}

.banner-counter {
	position: absolute;
	left: 0;
	bottom: 0;
	padding-bottom: 30px;
	padding-left: 45px;
}

.banner-counter-item {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	margin-right: 5px;
}

.banner-counter-item:not(.active) {
	background-color: #050911;
	opacity: 0.2;
}

.banner-counter-item.active,
.banner-counter-item:not(.active):hover {
	background-color: #ffcb2b;
	opacity: 1;
}

.quick-links {
	margin-top: 30px;
	margin-bottom: 30px;
}

.quick-link {
	height: 70px;
	padding: 0 30px;
	transition: 0.3s;
	overflow: hidden;
}

.quick-link span {
	z-index: 2;
}

.quick-link:hover {
	opacity: 0.85;
}

.quick-link.decks,
.page-header.decks {
	background-color: #7a32e6;
}

.quick-link.cards,
.page-header.cards {
	background-color: #ff9900;
}

.quick-link.sets,
.page-header.sets {
	background-color: #f94d36;
}

.page-header.builder {
	background-color: #f4f2f6;
}

.quick-link-pokeball {
	position: absolute;
	right: -25px;
	border-radius: 0 6px 6px 0;
}

.pokeball.decks path {
	fill: #5f25b7;
}

.pokeball.cards path {
	fill: #e28b08;
}

.pokeball.sets path {
	fill: #e1321a;
}

.pokeball.builder path {
	fill: #e6e3e9;
}

.home-hero img {
	border: 1px solid #ddd;
	border-radius: 17px;
}

.btn-tiles,
.btn-copy {
	margin-right: 5px;
}

.header-btn {
	height: 48px;
	padding: 0 18px;
}

.nav-btn {
	height: 48px;
	margin-bottom: 10px;
}

.header-login-btn {
	margin-left: 10px;
}

.deck-preview-engagement-icon {
	margin-right: 5px;
	font-size: 0.9rem;
	margin-left: 12px;
	color: #9b8da8;
}

.deck-preview-engagement-icon.likes:hover,
.deck-preview-engagement-icon.likes.active {
	color: #e32c26;
}

.deck-preview-engagement-icon.comments:hover,
.deck-preview-engagement-icon.views:hover {
	color: #4e3569;
}

.page-header {
	height: 100px;
	margin-bottom: 30px;
	overflow: hidden;
}

.page-header-pokeball {
	margin-left: 30px;
}

.page-header-bg {
	position: absolute;
	top: 0;
	right: -25px;
}

.page-header-pokeball {
	height: 100px !important;
}

.page-header-title {
	font-weight: 700;
}

.format-picker {
	padding: 9px 8px;
}

.format-picker:first-of-type {
	margin-left: 15px;
}

.format-picker.active {
	opacity: 1;
	background-color: #46296c;
	font-family: "Rajdhani-Bold";
	font-weight: 700;
}

.filter-icon {
	margin-right: 7px;
}

.page-content-header {
	height: 58px;
	padding: 0 20px;
	margin-bottom: 30px;
}

.deck-view-picker {
	height: 38px;
	width: 44px;
}

.deck-view-picker.active {
	background-color: #46296c;
}

.deck-details {
	overflow: hidden;
	margin-bottom: 15px;
}

.deck-details-header {
	padding: 20px;
	padding-right: 50px;
}

.deck-details-body {
	padding: 10px 0;
}

.deck-details-row {
	padding: 0 20px;
	margin: 0;
	width: 100%;
}

.deck-details-row .col {
	padding: 10px 0;
}

.border-bottom {
	border-bottom: 1px solid rgba(5, 9, 17, 0.1) !important;
}

.deck-details-footer {
	background-color: #e6e3e9;
	padding: 10px 20px;
}

.share-btn {
	height: 45px;
}

.card-info-header-btn {
	margin-right: 10px;
	height: 38px;
	width: 250px;
}

.builder-header-btn {
	margin-right: 10px;
	height: 38px;
	width: 195px;
}

.card-info {
	overflow: hidden;
}

.overflow-hidden {
	overflow: hidden;
}

.card-info-img {
	padding: 20px;
}

.card-info-set-img {
	height: 230px;
}

.card-info-set-img img {
	height: 130px;
}

.card-info-prices {
	padding: 10px 0;
}

.card-info-price {
	padding: 0 20px;
	margin: 0;
	width: 100%;
}

.card-info-price .col {
	padding: 10px 0;
}

.card-attr-header {
	padding: 15px 20px;
}

.card-attr {
	margin-bottom: 15px;
}

.card-attr-blocks {
	padding: 20px 20px 0 20px;
}

.card-attr-blocks.padded {
	padding: 20px;
}

.card-attr-block {
	padding: 15px 5px;
	height: 100px;
}

.card-attr-details {
	padding: 10px 0;
}

.card-attr-detail {
	padding: 0 20px;
	margin: 0;
	width: 100%;
}

.card-attr-detail .col {
	padding: 10px 0;
}

.card-attr-block-title {
	margin-bottom: 5px;
	line-height: 1rem;
	display: block;
}

.card-attr-block-data {
	line-height: 1rem;
}

.card-attr-icon {
	height: 20px;
	width: 20px;
	border-radius: 50%;
}

.attack-icon {
	margin-right: 10px;
}

.card-attr-type-name {
	margin-left: 5px;
}

.card-attr-body.padded {
	padding: 5px 20px;
}

.card-attacks {
	padding: 15px 0;
}

.card-attack-text {
	margin-top: 15px;
}

.card-attacks:not(:last-of-type) {
	border-bottom: 1px solid rgba(5, 9, 17, 0.1) !important;
}

.card-info-btn-container {
	padding: 20px;
}

.card-info-gallery-btn {
	height: 55px;
}

.card-btn-container {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 10px 20px;
	display: none;
}

.comments-section {
	padding: 30px 0;
}

.tags-container {
	padding: 20px;
	margin-bottom: 80px;
}

.comments-container {
	padding: 20px 20px 5px 20px;
	margin-bottom: 80px;
}

.comment-block,
.tag-block {
	margin-bottom: 15px;
}

.comment-ellipse {
	height: 3px;
	width: 3px;
	border-radius: 50%;
}

.comment-info {
	margin-right: 5px;
}

.comment:not(:last-of-type) {
	margin-bottom: 15px;
}

.comment-header {
	margin-bottom: 5px;
}

.comment-login-container {
	padding: 15px;
}

.comment-login-icon {
	font-size: 2rem;
	margin-right: 10px;
}

.comment-input {
	margin-bottom: 10px;
}

.comment-btn {
	width: 120px;
}

.clipboard-toggle-copy {
	margin-right: 3px;
}

.btn-info-icon {
	margin-right: 3px;
}

.info-tooltip {
	opacity: 1 !important;
}

.info-tooltip span {
	color: #fff !important;
	font-family: Rajdhani-Medium;
	font-size: 1rem;
	text-transform: none !important;
}

input.modal-input {
	height: 48px;
}

textarea.modal-input {
	min-height: 290px;
}

.modal-btn {
	height: 48px;
	font-size: 1rem;
}

.modal-btn.sm {
	min-width: 130px;
}

.p-label {
	margin-bottom: 10px;
	margin-top: -8px;
}

.login-title {
	margin-bottom: 20px;
}

.login-input {
	height: 48px;
	margin-bottom: 20px;
}

.login-block {
	margin-bottom: 20px;
}

.form-error-msg {
	display: block;
	margin-bottom: 20px;
}

.clipboard-section {
	overflow: hidden;
}

.clipboard-section.deck-section {
	margin-bottom: 25px;
}

.clipboard-section-header {
	height: 56px;
	padding: 0 20px;
}

.clipboard-section-body {
	padding: 20px;
}

.clipboard-section-body:not(.card-section-body) {
	min-height: calc(420px - 56px);
}

.clipboard-item {
	margin-bottom: 5px;
	padding: 10px;
}

.clipboard-item:not(.deck-clipboard-item) {
	cursor: pointer;
}

.clipboard-footer {
	position: fixed;
	left: 0;
	bottom: 0;
	height: 70px;
	display: none;
}

.clipboard-item-delete {
	position: absolute;
	height: 100%;
	top: 0;
	right: 10px;
}

.clipboard-qty-picker {
	width: 80px;
}

.clipboard-name-container {
	width: calc(100% - 100px);
}

.deck-clipboard-name:hover {
	text-decoration: underline !important;
}

.set-date-container {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 38px;
}

.preview-empty {
	height: 400px;
}

.deck-clipboard-item:not(.owned) .clipboard-qty-picker {
	width: 40px;
}

.deck-clipboard-item:not(.owned) .clipboard-name-container {
	width: calc(100% - 40px);
}

.deck-header-btn {
	width: 160px;
	margin-right: 10px;
	height: 38px;
}

.add-cards-paste {
	height: 400px;
}

.nav-panel.active {
	height: calc(100% - 70px);
	right: 0;
	width: 100%;
	border-radius: 0;
	bottom: 0 !important;
}

.nav-btn-container {
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 10px;
}

.deck-details-sm {
	display: none;
}

input.error,
input.error:focus {
	border-color: #f94d36 !important;
}

.delete-deck-btn {
	height: 55px;
}

.desc-input {
	margin-top: 10px;
}

.header-user-img {
	height: 48px;
	width: 48px;
	background-color: #46296c;
	border-radius: 50%;
	margin-left: 15px;
}

.header-user-img:hover {
	background-color: #46296c !important;
}

.header-user-img:active {
	background-color: #412269 !important;
}

.comment-wrapper {
	width: 100%;
	background: transparent;
	color: #a9b5c4;
}

.comment-wrapper .comment-wrapper__control {
	border-radius: 6px;
	border: 1px solid #ddd;
	min-height: 70px;
}

.comment-wrapper .comment-wrapper__control .comment-wrapper__highlighter {
	padding: 10px;
	min-height: 70px;
	border-radius: 6px;
}

.comment-wrapper.reply .comment-wrapper__control {
	border-radius: 6px;
	border: 1px solid #ddd;
	min-height: 38px;
}

.comment-wrapper.reply .comment-wrapper__control .comment-wrapper__highlighter {
	padding: 10px;
	min-height: 38px;
	border-radius: 6px;
}

.comment-wrapper .comment-wrapper__control .comment-wrapper__input {
	padding: 10px;
	outline: 0;
	border: 0;
	resize: none;
	outline: none;
	overflow: hidden;
}

.comment-wrapper__suggestions {
	border: 1px solid #ddd;
	border-radius: 6px;
}

.comment-wrapper__suggestions .comment-wrapper__suggestions__list {
	font-size: 14px;
}

.user-suggestions {
	padding: 10px;
}

.user-suggestions b {
	font-family: "Rajdhani-Bold" !important;
}

.user-suggestions.focused {
	background-color: #ddd;
}

.mentioned {
	background-color: #ddd;
	font-family: "Rajdhani-Regular" !important;
	padding: 0 !important;
	margin: 0 !important;
}

.reply-form {
	padding-left: 30px;
	border-left: 1px solid #ddd;
}

.comment-replies {
	margin-top: 10px;
}

.comment-container:not(.reply-container):not(.reply-form) {
	padding-top: 20px;
}

.comment-container.reply-container {
	padding: 0px 0 10px 30px;
	border-left: 1px solid #ddd;
}

.reply-btn {
	position: absolute;
	height: 32px;
	right: 20px;
	bottom: 6px;
	width: 50px;
}

.mention-display {
	color: #764aac !important;
}

.notification-btn {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	margin-left: 15px;
	font-size: 1.15rem !important;
	box-shadow: none !important;
}

.notification-btn:hover {
	background-color: #46296c !important;
}

.notification-btn:active {
	background-color: #412269 !important;
}

.notif-counter {
	position: absolute;
	top: 5px;
	left: 23px;
	height: 16px;
	/* width: 27px; */
	padding: 0 7px;
	border-radius: 10px;
	font-size: 0.7rem;
	color: #fff;
}

.notification-container {
	overflow: hidden;
	width: 320px;
	background-color: #5f388b !important;
}

.notification {
	width: 100%;
}

.compare-user {
	min-height: 200px;
}

.compare-deck {
	padding: 10px;
	cursor: pointer;
	user-select: none;
}

.compare-deck:hover {
	background-color: #f7f0fe;
}

.compare-deck:active {
	background: #3a2156;
}

.compare-deck:active span {
	color: #fff !important;
}

.compare-loader {
	border-width: 2px;
	color: #3a2156;
}

.compare-back {
	cursor: pointer;
}

.card-loader {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
}

/* OTHER SCREENS */

@media screen and (max-width: 1200px) {
	.filter-btn-container {
		display: none;
	}

	.md-format-dropdown {
		display: flex;
	}
}

/* LARGE */
@media screen and (max-width: 992px) {
	.nav-lg {
		display: none;
	}

	.nav-sm {
		display: inherit;
	}

	.container {
		max-width: 100% !important;
	}

	.deck-header {
		height: 25vh;
	}

	.clipboard-preview {
		display: none;
	}

	.pagination-container {
		width: 100%;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		padding: 0 !important;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.pagination-btn.previous {
		position: absolute;
		left: 0;
	}

	.pagination-btn.next {
		position: absolute;
		right: 0;
	}

	.format-dropdown {
		margin-left: 0;
	}

	.page-header-pokeball:not(:last-of-type) {
		display: none;
	}

	.card-info {
		margin-bottom: 20px;
	}

	.comment-header {
		display: block;
	}

	.comment-ellipse {
		display: none;
	}

	.comment-date {
		display: block;
		font-size: 0.8rem;
		width: 100%;
	}

	.deck-details-lg {
		display: none;
	}

	.deck-details-sm {
		display: block;
		margin-bottom: 20px;
	}

	.settings-val-container {
		margin-bottom: 20px;
	}

	.settings-action-container {
		-webkit-justify-content: left;
		justify-content: left;
	}

	.settings-action-container .btn {
		width: 170px !important;
	}
}

/* MEDIUM */
@media screen and (max-width: 768px) {
	.banner-pikachu {
		display: none;
		/* height: 22vh;
		right: 3vw; */
	}

	.banner-text-container {
		padding: 0 15px;
	}

	.banner-text {
		color: #fdcf09;
		font-size: 2.2rem !important;
		line-height: 2.3rem;
	}

	.banner-btn-container {
		-webkit-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}

	.banner-btn {
		width: 100%;
	}

	.banner-btn:first-of-type {
		margin-bottom: 10px;
	}

	.footer-nav > .col {
		text-align: center;
	}

	.footer-nav > .col:first-of-type {
		margin-bottom: 2rem;
	}

	.footer-nav-link {
		margin: 2rem 0.5rem;
	}

	.footer-social {
		margin: 2rem !important;
	}

	.footer-text {
		padding: 20px 2vw;
		font-size: 0.8rem;
	}

	.deck-control > .col:first-of-type {
		margin-bottom: 2rem;
		font-size: 0.75rem;
	}

	.deck-btn {
		font-size: 0.7rem;
	}

	.deck-card-preview {
		display: none;
	}

	.section-header {
		font-size: 1rem;
	}

	.clipboard {
		height: 50px;
	}

	.clipboard-header {
		height: 50px;
		padding: 0 1.5rem;
	}

	.clipboard-toggle {
		height: 100%;
	}

	.clipboard.active {
		height: 100%;
	}

	.clipboard-body {
		height: calc(100vh - 50px);
		padding-bottom: 100px;
	}

	.clipboard-cards-body {
		height: 90%;
	}

	.clipboard-cards-options {
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		height: 10%;
	}

	.clipboard-btn {
		width: calc(50% - 10px);
		float: right;
		margin: 0;
	}

	.clipboard-btn:last-of-type {
		margin-left: 20px !important;
	}

	.quick-link {
		margin-bottom: 15px;
	}

	.home-nav-tabs {
		padding: 0 15px;
		-webkit-justify-content: center;
		justify-content: center;
	}

	.home-nav-tabs .nav-link {
		padding: 10px !important;
	}

	.banner-counter {
		padding-bottom: 10px;
		-webkit-justify-content: center;
		justify-content: center;
	}

	.card-filters,
	.deck-filters {
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		padding: 20px !important;
	}

	.md-format-dropdown {
		display: none;
	}

	.sm-format-dropdown {
		display: flex;
	}

	.search-container {
		width: 100% !important;
	}

	.filter-btn {
		text-align: right !important;
		padding-right: 0;
	}

	.filter-btn:hover,
	.filter-btn:focus {
		background-color: #3a2156 !important;
	}

	.sm-format-container {
		margin-top: 15px;
	}

	.clipboard-section {
		margin-bottom: 15px;
	}

	.clipboard-header-container {
		padding: 0;
	}

	.clipboard-header {
		width: 100%;
		padding: 0;
	}

	.clipboard-toggle {
		width: 100%;
	}

	.card-info-header-btn {
		width: 100% !important;
	}

	.card-info-header-btn:first-of-type {
		margin-bottom: 10px;
	}

	.page-content-header:not(.keep-row) {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
	}

	.deck-content-btn-container {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		flex-direction: row;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	.deck-header-btn {
		width: calc(50% - 10px);
		margin: 0;
	}

	.deck-view-picker-container {
		-webkit-justify-content: center;
		justify-content: center;
	}

	.deck-header-btn:first-of-type {
		margin-right: 20px;
	}

	.card-info-header {
		height: 130px;
	}

	.deck-content-header {
		height: fit-content !important;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.footer-socials {
		text-align: center !important;
	}

	.notification-container {
		max-width: 100% !important;
		width: 100% !important;
	}
}
